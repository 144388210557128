import request from '@/utils/request'

const apiService = {

	api_partner: function (params) {
	    return request({ url: 'api/partner', method: 'GET', params})
	},
	api_join: function (params) {
	    return request({ url: 'api/join', method: 'GET', params})
	},
	recruitment: function (params) {
	    return request({ url: 'api/recruitment', method: 'GET', params})
	},
	upload: function (data) {
	    return request({ url: 'tools/upload', method: 'POST',data:data})
	},
	get_token: function () {
	    return request({ url: 'api/token', method: 'GET'})
	},
	resume: function (data) {
	    return request({ url: 'api/resume', method: 'POST',data})
	},

	getNewsList:function (params){
		return request({ url: 'api/news', method: 'GET',params})
	},
	getPerformanceList:function (params){
		return request({ url: 'api/performance', method: 'GET',params})
	},
	getFinancialReportList:function (params){
		return request({ url: 'api/financialReport', method: 'GET',params})
	},

	// api_partner: function (params) {
	// 	return request({ url: 'partner', method: 'GET', params})
	// },
	// api_join: function (params) {
	// 	return request({ url: 'join', method: 'GET', params})
	// },
	// recruitment: function (params) {
	// 	return request({ url: 'recruitment', method: 'GET', params})
	// },
	// upload: function (data) {
	// 	return request({ url: 'tools/upload', method: 'POST',data:data})
	// },
	// get_token: function () {
	// 	return request({ url: 'token', method: 'GET'})
	// },
	// resume: function (data) {
	// 	return request({ url: 'resume', method: 'POST',data})
	// },
	//
	// getNewsList:function (params){
	// 	return request({ url: 'news', method: 'GET',params})
	// },
	// getPerformanceList:function (params){
	// 	return request({ url: 'performance', method: 'GET',params})
	// },
	// getFinancialReportList:function (params){
	// 	return request({ url: 'financialReport', method: 'GET',params})
	// },
}

export default apiService



