<template>
  <img style="width: 100%;height: 100%;margin-bottom: 47px;" src="../../public/img/hzst.png" fit="fill" />
	<div v-if="list.length" style="position: relative;">

<!--		<el-carousel height="600px" indicator-position="none" @change="lbuChange" :autoplay="false">-->
<!--			<el-carousel-item v-for="(item,index) in list"  :key="index">-->
<!--				<div class="bjimg flex center" :style="{backgroundImage: 'url('+imgurl+item.img+')'}">-->
<!--					<div class="flex_equally">-->
<!--						<div class="title">-->
<!--							{{item.title}}-->
<!--							<i></i>-->
<!--						</div>-->
<!--						<div class="describe">{{item.desc}}</div>-->
<!--						<div class="flex center">-->
<!--							<div class="seat">仓谷数字</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</el-carousel-item>-->
<!--		</el-carousel>-->
<!--		<div class="indicator flex center">-->
<!--			<div v-for="item in list.length" :key="item" :class="{'active':indexID === item-1}"></div>-->
<!--		</div>-->
	</div>
	<div v-if="page_data.length">
		<div style="background-color: #ffffff;">
			<div class="subject-width plate1">
				<div class="title">{{page_data[1].title}}</div>
				<div class="describe">{{page_data[1].description}}</div>
				<div class="item">
					<img :src="imgurl+page_data[1].content[0].img" alt="" style="height: 405px;width: 100%;">
					<div class="txt">
						<el-row :gutter="15">
							<el-col :xs="24" :sm="16" :md="14" :lg="12" :xl="10">
								<div class="title">{{page_data[1].content[0].title}}</div>
								<div class="describe">{{page_data[1].content[0].desc}}</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<div class="subject-width plate2" >
			<div class="title">仓谷数字</div>
			<div class="describe">通过业务、技术、商业三大链接，为家装行业提供最好最全的家装服务</div>
			<el-row :gutter="15" justify="space-between">
				<el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
					<div class="plate-img">
						<img src="../assets/base.png" alt="" style="width: 330px;height: 180px;">
					</div>
					<div class="titles">乡村振兴示范基地</div>
					<div class="describes">这是关于易空间乡村振兴示范基地的介绍易空间共同富裕乡村振兴示范基地</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
					<div class="plate-img">
						<img src="../assets/pt.png" alt="" style="width: 330px;height: 180px;">
					</div>
					<div class="titles">荣装网家庭生活平台</div>
					<div class="describes">这是关于易空间乡村振兴示范基地的介绍易空间共同富裕乡村振兴示范基地</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
					<div class="plate-img">
						<img src="../assets/zh.png" alt="" style="width: 330px;height: 180px;">
					</div>
					<div class="titles">科创智慧</div>
					<div class="describes">这是关于易空间乡村振兴示范基地的介绍易空间共同富裕乡村振兴示范基地</div>
				</el-col>
			</el-row>
		</div>
		<div style="background-color: #ffffff;">
			<div class="subject-width plate3">
				<div class="title">{{page_data[2].title}}</div>
				<div class="describe">{{page_data[2].description}}</div>
				<el-row :gutter="15" justify="space-between">
					<el-col :xs="24" :sm="24" :md="15" :lg="17" :xl="17">
						<div class="left">
							<el-carousel height="273px"   :autoplay="false">
								<el-carousel-item v-for="(item,index) in page_data[2].content"  :key="index">
									<el-image style="width: 100%;height: 100%;margin-bottom: 47px;" :src="imgurl+item.img" fit="none" />
								</el-carousel-item>
							</el-carousel>

							<div class="title" style="text-align: left;">仓谷数字介绍</div>
							<div class="describe" style="text-align: left;line-height: 37px;">仓谷数字，致力于成为全国最大规模的互联网家装平台，仓谷数字，致力于成为全最大规模的互联网家装平台，成为全国最大规模的互联网家装平台，仓谷数字，致力于成为全最大规模的互联网家装平台，</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="9" :lg="7" :xl="7">
						<div class="flex column start" style="height: 100%;">
							<div class="right" style="margin-bottom: 10px;">
								<div class="titlef">孵化加速</div>
								<div class="describex">仓谷数字，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div>
							</div>
							<div class="right" style="margin-bottom: 10px;">
								<div class="titlef">大企业创新</div>
								<div class="describex">仓谷数字，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div>
							</div>
							<div class="right">
								<div class="titlef">创业投资</div>
								<div class="describex">仓谷数字，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="subject-width plate4">
			<div class="title">{{page_data[3].title}}</div>
			<div class="describe">{{page_data[3].description}}</div>
			<el-row :gutter="15" justify="space-between">
				<el-col :xs="24" :sm="24" :md="15" :lg="12" :xl="12">
					<div class="left">
						<el-carousel height="419px"   :autoplay="false">
							<el-carousel-item v-for="(item,index) in page_data[3].content"  :key="index">
								<el-image style="width: 100%;height: 100%;margin-bottom: 47px;" :src="imgurl+item.img" fit="none" />
							</el-carousel-item>
						</el-carousel>

					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="9" :lg="12" :xl="12">
					<div class="right" >
						<div class="titlef">云+生态</div>
						<!-- <div class="describex">仓谷数字，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div> -->
					</div>
					<div class="right">
						<div class="titlef">免费对象</div>
						<div class="describex">仓谷数字，致力于成为全国最大规模的互联网家装平台，仓谷数字，致力于成为全国最大规模的互联网家装平台</div>
					</div>
					<div class="right">
						<div class="titlef">扶持内容</div>
						<div class="describex">仓谷数字，致力于成为全国最大规模的互联网家装平台，仓谷数字，致力于成为全国最大规模的互联网家装平台</div>
					</div>
				</el-col>
			</el-row>
		</div>

	</div>


</template>

<script setup>
	import apiService from '@/api/index';
	import { ref, onMounted, reactive } from "vue";
	const list = ref([]);
	const indexID = ref(0);
	const imgurl = window.Image_url
	const page_data = ref([])
	apiService.api_partner().then((res)=>{
		console.log(res)
		page_data.value = res.data
		list.value = res.data[0].content
	});
	const lbuChange = (e)=>{
		indexID.value = e
	};

</script>

<style scoped lang="scss">
	.plate4{
		.right{
			.describex{
				color: #333333;
				line-height: 37px;
				font-size: 20px;
			}
			.titlef{
				color: #333333;
				font-size: 28px;
			}
			margin-bottom: 20px;
		}
		.describe{
			font-size: 24px;
			font-family: AlibabaPuHuiTi_2_65_Medium;
			color: #555555;
			line-height: 37px;
			text-align: center;
			padding-top: 16px;
			margin-bottom: 40px;
		}
		.title{
			font-size: 34px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			line-height: 48px;
			text-align: center;
		}
		padding: 45px 90px;
	}
	.plate3{
		.right{
			.describex{
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_65_Medium;
				color: #555555;
				line-height: 37px;
			}
			.titlef{

				color: #333333;
				line-height: 40px;
				font-size: 28px;
			}
			background: #FCFBFB;
			box-shadow: 0px 2px 20px 5px rgba(215,187,185,0.19);
			border: 2px solid #FFFFFF;
			padding: 10px 12px;
		}
		.left{
			padding: 34px 43px;
			background: #FCFBFB;
			box-shadow: 0px 2px 20px 5px rgba(215,187,185,0.19);
			border: 2px solid #FFFFFF;
		}
		.describe{
			color: #555555;
			font-size: 24px;
			padding-top: 14px;
			text-align: center;
			margin-bottom: 54px;

		}
		.title{
			color: #2A2A2A;
			font-size: 34px;
			text-align: center;
			font-weight: 600;
		}
		padding: 94px 0 ;
	}
	.plate1{
		.item{
			.txt{
				.describe{
					color: #555555;
					font-size: 24px;
					padding-top: 14px;
					text-align: left;

				}
				.title{
					color: #333333;
					font-size: 34px;
					text-align: left;
				}
				position: absolute;
				left: 30px;
				bottom: 0px;
				text-align: left;
				padding-bottom: 40px;
			}
			text-align: center;
			position: relative;
		}
		.describe{
			color: #555555;
			font-size: 24px;
			padding-top: 14px;
			text-align: center;
		}
		.title{
			color: #2A2A2A;
			font-size: 34px;
			text-align: center;
			font-weight: 600;
		}
		padding: 54px 0;
	}
	.plate2{
		.plate-img{
			text-align: center;
		}
		.describes{

			font-size: 24px;
			font-family: AlibabaPuHuiTi_2_65_Medium;
			color: #555555;
			line-height: 37px;
		}
		.titles{
			color: #333333;
			font-size: 34px;
			margin-bottom: 16px;
			padding-top: 34px;
		}
		.describe{
			color: #555555;
			font-size: 24px;
			text-align: center;
			padding-top: 16px;
			padding-bottom: 98px;
		}
		.title{
			color:#333333;
			font-size: 34px;
			text-align: center;
			font-weight: 600;
		}
		padding:45px 0;
	}
	.bjimg {
		.seat{
			width: 134px;
			height: 42px;
			border-radius: 10px;
			border: 1px solid #FFFFFF;
			color: #ffffff;
			line-height: 42px;
			text-align: center;
			margin-top: 40px;
		}
		.describe{
			color: #ffffff;
			font-size: 20px;
			text-align: center;
			padding-top: 30px;
		}
		.title{
			i{
				position: absolute;
				bottom: -10px;
				left:0;
				right: 0;
				margin:auto  ;
				width: 74px;
				border: 1px solid #FFFFFF;
			}
			margin-bottom: 10px;
			text-align: center;
			color:#ffffff;
			font-size: 36px;
			position: relative;
		}
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

	}
	.indicator {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 52px;
		margin: auto;
		color: #ffffff;
	}

	.indicator>div {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: #ffffff;
		margin: 0 4px;
	}

	.indicator .active {
		height: 6px;
		border-radius: 10px;
		background-color: #ffffff;
		margin: 0 4px;
		width: 30px;
		transition: width 0.3s
	}
</style>
