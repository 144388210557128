<template>
	<img  style="width: 100%;height: 100%;margin-bottom: 47px;" src="../../public/img/jr.jpeg" fit="fill" />
	<div class="subject-width">

<!--		<div class="Copywriting">-->
<!--			<p class="summary0">{{join.content}}</p>-->
<!--			<p class="summary1">{{join.content_en}}</p>-->
<!--		</div>-->
		<div>
			<div class="title">招聘岗位</div>
			<div style="padding-bottom: 20px;">
				<div class="post" v-for="(item,index) in recruitment" :key="index">
					<div class="titles">岗位名称：{{item.title}}</div>
					<div>岗位要求：{{item.content}}</div>
					<div class="describe">岗位描述：{{item.desc}}</div>
					<el-button type="success" plain @click="delivery(item.id)">一键投递</el-button>
				</div>
			</div>

			<el-dialog v-model="dialogVisible" title="上传简历" width="30%" :before-close="()=>dialogVisible = false">
				<div>
					<el-upload class="upload-demo" drag ref="uploadRef"
					 :http-request="uploadAction"
					 :auto-upload="false"
						:on-exceed="handleExceed" :limit="1" :before-upload="beforeUpload"
						action="#"
						multiple>
						<el-icon class="el-icon--upload">
							<upload-filled />
						</el-icon>
						<div class="el-upload__text">
							Drop file here or <em>click to upload</em>
						</div>
						<template #tip>
							<div class="el-upload__tip">
								简历不能大于5MB.
							</div>
						</template>
					</el-upload>
				</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisible = false">取消</el-button>
						<el-button type="primary" @click="submitUpload ">
							投递
						</el-button>
					</span>
				</template>
			</el-dialog>
		</div>

	</div>
</template>

<script setup>
	import { ref, onMounted, reactive } from "vue";
	import { UploadFilled } from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus';
	const dialogVisible = ref(false);
	const imgurl = window.Image_url;
	import apiService from '@/api/index';
	let obj = {
		file_path : null,
		r_id:null
	}
	const delivery = (id) => {
		obj.r_id = id
		dialogVisible.value = true
	};
	const join = ref({});
	apiService.api_join().then((res)=>{
		join.value = res.data
	});
	const recruitment = ref([]);
	apiService.recruitment().then((res)=>{
		recruitment.value = res.data
	});

	const handleExceed = ()=>{

	}
	const uploadAction = (option)=>{
		let param = new FormData();
		param.append('file', option.file);
		apiService.upload(param).then((res)=>{
			obj.file_path = res.data.file_path
			apiService.get_token().then((res)=>{
				window.sessionStorage.setItem('auth-token',res.data.token)
				apiService.resume(obj).then((res)=>{
					if(res.code === 200){
						 ElMessage.success('投递成功！');
						 uploadRef.value.clearFiles();
						 dialogVisible.value = false
					}
				});
			});
		});
	}
	const beforeUpload = (file)=>{
		const type = ['image/jpeg', 'image/jpg', 'image/png']
            if (type.indexOf(file.type)>=0) {
                ElMessage.error('上传的文件不能是图片')
                return false
            } else if (file.size / 1024 / 1024 > 5) {
                ElMessage.error('图片大小不能超过5MB!')
                return false
            }
        return true
	}
	const uploadRef = ref('');

	const submitUpload = () => {
		uploadRef.value.submit()
	};
</script>

<style scoped lang="scss">
	.post {
		>div {
			margin-bottom: 10px;
		}

		.describe {
			color: #999999;

		}

		.titles {
			color: #000;
			font-weight: bold;
		}

		margin-top: 20px;
		padding: 15px;
		border-radius: 10px;
		background-color: #ffffff;
		margin-bottom: 10px;
	}

	.title {
		padding: 35px 0;
		text-align: center;
		font-size: 40px;
		color: #000;
		border-bottom: 1px solid #ddd;
	}

	.Copywriting {
		.summary0 {
			font-size: 20px;
			color: #666666;
			line-height: 180%;
		}

		.summary1 {
			font-size: 11px;
			color: #999;
			margin-top: 30px;
			line-height: 180%;
		}

		padding: 70px 0;
		text-align: center;

	}
</style>
