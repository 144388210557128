<template>
	<div class="BottomNavigation">
		<div  style="max-width: 1400px;margin: 0 auto;">
			<el-row>
				<el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
					<div class="flex">
						<div class="item">
							<div class="title">关于仓谷</div>
							<p>了解我们</p>
							<p>联系我们</p>
						</div>
						<div class="item">
							<div class="title">关注仓谷</div>
							<p>新浪微博</p>
							<p>新闻中心</p>
						</div>
					</div>
				</el-col>

				<el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
					<div class="flex center">
						<div>
							<img src="../assets/wenxin-gr.jpg" style="width: 107px;height: 107px;" alt="">
							<p style="color: #ffffff;font-size: 16px;text-align: center;">微信公众号</p>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
					<div style="color: #FFFFFF;margin-left: 46px;">
						地址：浙江省丽水市莲都区古城路163号二楼
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="18">
					<div style="color: #FFFFFF;margin-left: 46px;">
						服务热线： 400-622-1988
					</div>
				</el-col>
			</el-row>

		</div>
	</div>


	<div class="beian">
<!--		<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #ffffff;">浙ICP备14007572号-3 </a>-->
<!--    Copyright @2016 浙江仓谷数字科技股份有限公司 版权所有-->
<!--    <div style="width:600px;margin:0 auto; padding:20px 0;">-->
<!--      <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33110202000700" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">-->
<!--        <img src="../../public/img/icp.png" style="float:left;"/>-->
<!--        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33110202000700号 @2019 浙江仓谷数字科技股份有限公司 版权所有</p ></a >-->
<!--    </div>-->


    <div href="" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
      <img src="../../public/img/icp.png" style="float:left;"/>
      <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;color:#939393; "><a
          style="color:#939393;" target="_blank"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33110202000709">浙公网安备
        33110202000709号</a>
        <a target="_blank" style="margin-left: 10px;color:#939393;" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备14007572号-6</a>
        @2023 浙江仓谷数字技术股份有限公司 版权所有</p></div>

  </div>
</template>

<script>
	export default {
		name: 'BottomNavigation',
		data() {
			return {

			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped lang="scss">
	.beian{
		font-size: 15px;
		text-align: center;
		padding: 20px 5px;
		background-color: #404243;
		color: #FFFFFF;
	}
.BottomNavigation{

	.item{
		.title{
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 19px;
		}
		p{
			margin-bottom: 8px;
			font-size: 14px;
		}
		color: #FFFFFF;
		margin-left: 46px;
	}

	width: 100%;
	background-color: #575A5B;
	padding: 44px 0;
}
</style>
