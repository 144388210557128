import { defineStore } from 'pinia'
export const useStore = defineStore('index', {
    state:()=>{
        return {
            curIndex: -1,

        };
    }
    // 其它配置项
})
