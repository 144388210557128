import axios from 'axios'


import {
	ElMessage,ElLoading
} from 'element-plus'





import router from '../router/index'




const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 5000
})

let  loading =null


service.interceptors.request.use(function config(config) {
	config.headers['auth-token'] = window.sessionStorage.getItem('auth-token');
		loading = ElLoading.service({
			lock: true,
			text: 'Loading',
			background: 'rgba(0, 0, 0, 0.7)',
		});
		return config;
	},
	error => {

		return Promise.reject(error)
	}
)




service.interceptors.response.use(function response(response) {
		loading.close();
		switch (response.data.code) {
			case 200:
				return response.data
			case 400:
				ElMessage({
					message: response.data.message,
					type: 'error',
					duration: 4000
				})

				return response.data
			case 20000:
				ElMessage.error(response.data.message)
				setTimeout(function() {
					router.push('/');
				}, 1500);

				return response;
			case -1:
				ElMessage.error(response.data.message);
				return Promise.reject()
			default:
				ElMessage.error(response.data.message);

				return;
		};
		return response
	},
	error => {

	loading.close();
		return Promise.reject(error)
	}
)

export default service
